<template>
  <div class="readme-article">
    <h2 id="客户群标签">2.客户群标签</h2>
    <h3 id="群标签列表">2.1群标签列表</h3>
    <p>
      查看群标签列表，包含群标签组名称、子标签等信息，点击修改按钮可修改群标签组内容。
    </p>
    <p>
      <img src="@/assets/img/scrm/5-3.png" />
    </p>
    <h3 id="群标签编辑">2.2群标签编辑</h3>
    <p>
      群标签组添加修改。
    </p>
    <p>
      <img src="@/assets/img/scrm/5-4.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm5-2",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>